import MainLayout from '../../components/layout/MainLayout';
import React from 'react';
import Breadcrumb from '../../components/collection/CollectionBreadcrumb';
import Section from '../../components/Section';
import { Button, List, Typography } from 'antd';
import { Collection } from '../../types/Collection';
import { useCollection } from '../../hooks/use-collection';
import CollectionCard from '../../components/collection/CollectionCard';
import { Link } from 'gatsby';
import { filterParentCollections } from '../../utils/collectionUtil';
import { isEmpty } from 'lodash';
import SearchEngineOptimization from '../../components/utility/seo';

const { Text } = Typography;

const CollectionList = ({
  parent,
  child,
}: {
  parent: Collection;
  child: Collection[];
}) => {
  return (
    <div className="mb-3 border border-light-divider shadow-small rounded-[6px] overflow-hidden">
      <div className="bg-white px-6 py-3 border-b border-light-divider">
        <Text>{parent.title}</Text>
      </div>
      <div className="bg-light-lighter p-6">
        <div className="grid gap-3 grid-cols-2 md:grid-cols-4 lg:grid-cols-6 w-full">
          {child.map((collection) => (
            <CollectionCard collection={collection} />
          ))}
        </div>
        <Link to={`/collections/${parent.handle}`}>
          <Button className="!text-primary-6 mt-6">ดูประเภทสินค้านี้</Button>
        </Link>
      </div>
    </div>
  );
};

const AllCollectionPage = () => {
  const { hierarchyCollections } = useCollection();
  if (isEmpty(hierarchyCollections)) return null;
  return (
    <MainLayout>
      <SearchEngineOptimization title="ประเภทสินค้าทั้งหมด" />
      <Breadcrumb />
      <Section className="py-6">
        <List
          dataSource={hierarchyCollections}
          renderItem={(item) => (
            <CollectionList parent={item} child={item.children.slice(0, 6)} />
          )}
        />
      </Section>
    </MainLayout>
  );
};

export default AllCollectionPage;
