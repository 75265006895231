import tailwindConfig from '../../../tailwind.config';
import HomeIcon from '../../icons/home';
import React from 'react';
import Section from '../Section';
import { Typography } from 'antd';
import { Link } from 'gatsby';
import { Collection } from '../../types/Collection';
import { Product } from '../../types/Product';

const resolveConfig = require('tailwindcss/resolveConfig');
const config = resolveConfig(tailwindConfig);

const { Text, Title } = Typography;

type HeaderType = 'product' | 'productList' | 'collection';

const Breadcrumb = ({
  collection,
  product,
  type = 'collection',
}: {
  collection?: Collection;
  product?: Product;
  type?: HeaderType;
}) => {
  return (
    <div className="bg-white border-b border-light-divider shadow-small">
      <Section className="!py-4">
        <div className="flex items-center mb-3">
          <Link to="/">
            <HomeIcon color={config.theme.colors.light.title} />
          </Link>
          <Text className="!text-light-border mx-2">/</Text>
          <Link to={type === 'collection' ? '/collections' : '/products'}>
            <Text
              className={`!text-light-${collection ? 'title' : 'placeholder'}`}
            >
              {type === 'collection' ? 'ประเภทสินค้า' : 'สินค้า'}
            </Text>
          </Link>
          {collection && (
            <>
              <Text className="!text-light-border mx-2">/</Text>
              <Link
                to={`/collections/${collection.handle}`}
                className={`${
                  product
                    ? '!text-light-title'
                    : 'last:!text-light-title last:font-medium'
                }`}
              >
                {collection.title}
              </Link>
            </>
          )}
          {product && (
            <>
              <Text className="!text-light-border mx-2">/</Text>
              <Text className="last:!text-light-title last:font-medium">
                {product.title}
              </Text>
            </>
          )}
        </div>
        {type === 'collection' && (
          <Title className="!text-light-title !mb-0" level={3}>
            {collection?.title || 'ประเภทสินค้าทั้งหมด'}
          </Title>
        )}
        {type === 'productList' && (
          <Title className="!text-light-title !mb-0" level={3}>
            สินค้าทั้งหมด
          </Title>
        )}
      </Section>
    </div>
  );
};

export default Breadcrumb;
